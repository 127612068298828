import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import useLocales from "../hook/uselocales";

function ConfirmOrder() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  return (
    <>
      <NavBar />
      <section className="w-screen flex flex-col items-center mt-14 gap-28">
        <div className="flex flex-col justify-center items-center gap-5">
          <ul className="flex flex-wrap justify-center items-center gap-5 font-Cushy_W01 text-[var(--text-color)]">
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("checkoutOptions")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("billingDetail")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span
                style={{ border: "2px solid red" }}
                className="block w-6 h-6 rounded-full bg-[#F0F0F0]"
              ></span>{" "}
              <p>{translate("confirmOrder")}</p>
            </li>
          </ul>
          <img
            src="/media/images/pep/web-img/orderConfirm.png"
            alt="orderConfirm"
          />
        </div>

        <div className="w-full flex justify-center">
          <button
            type="submit"
            className="font-Cushy_W01 text-white text-sm bg-[var(--primary-color)] rounded-xl py-4 px-16 "
            onClick={() => {
              localStorage.removeItem("product");
              navigate("/");
              window.location.reload();
            }}
          >
            {translate("continueShopping")}
          </button>
        </div>
      </section>
    </>
  );
}

export default ConfirmOrder;
