import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slice/cart";
import customerSlice from "./slice/customer";
import authSlice from "./slice/auth";

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    customer: customerSlice,
    auth: authSlice,
  },
});
