import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    AddProduct: (state, action) => {
      const index = state.items.findIndex(
        (object) => object.id === action.payload.id
      );

      if (index === -1) {
        state.items.push(action.payload);
      } else {
        state.items.splice(index, 1);
      }
    },
    FillProduct: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { AddProduct, FillProduct } = cartSlice.actions;

export default cartSlice.reducer;
