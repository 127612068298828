import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import RegisterAcc from "./RegisterAcc";
import ReturnCustomer from "./ReturnCustomer";
import { useNavigate } from "react-router-dom";
import useLocales from "../../hook/uselocales";

const OrderDetails = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [registerAcc, setRegisterAcc] = useState(true);
  const customer = localStorage.getItem("customer");
  useEffect(() => {
    if (customer) {
      navigate("/payment-details");
    }
  }, []);

  return (
    <>
      <NavBar />
      <section className="w-screen flex flex-col items-center mt-6 gap-5">
        <div className="mb-6">
          <ul className="flex flex-wrap justify-center items-center gap-5 font-Cushy_W01 text-[var(--text-color)]">
            <li className="flex justify-center items-center gap-3 ">
              <span
                style={{ border: "2px solid red" }}
                className="block w-6 h-6 rounded-full bg-[#F0F0F0]"
              ></span>{" "}
              <p>{translate("checkoutOptions")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("billingDetail")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("confirmOrder")}</p>
            </li>
          </ul>
        </div>

        <div className="w-full flex justify-center items-center">
          <form action="" className="w-[70vw] flex flex-col justify-center">
            <div className="font-Cushy_W01 text-[var(--text-color)] flex justify-center items-center gap-20 mb-6">
              <div className="flex items-center gap-2">
                <input
                  defaultChecked={registerAcc}
                  type="radio"
                  id="yes-btn"
                  name="subscribe"
                  className="custom-registerform-radio cursor-pointer"
                  required
                  onClick={() => {
                    setRegisterAcc(true);
                  }}
                />
                <label className=" cursor-pointer" htmlFor="yes-btn">
                  {translate("registerAccount")}
                </label>
              </div>

              <div className="flex items-center gap-2">
                <input
                  defaultChecked={!registerAcc}
                  type="radio"
                  id="no-btn"
                  name="subscribe"
                  className="custom-registerform-radio cursor-pointer"
                  required
                  onClick={() => {
                    setRegisterAcc(false);
                  }}
                />
                <label className=" cursor-pointer" htmlFor="no-btn">
                  {translate("returnCustomer")}
                </label>
              </div>
            </div>
            {registerAcc ? <RegisterAcc /> : <ReturnCustomer />}
          </form>
        </div>
      </section>
    </>
  );
};

export default OrderDetails;
