import React, { useEffect, useState } from "react";
import ItemsCategory from "./ItemsCategory";
import ItemsCards from "./ItemsCards";
import axios from "axios";
import { API_END_POINT } from "../../../constant/data";

function Items() {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  return (
    <section className="py-20 px-16">
      <div className="w-full flex flex-row">
        <ItemsCategory
          setProductList={setProductList}
          setLoading={setLoading}
        />
        <ItemsCards loading={loading} productList={productList} />
      </div>
    </section>
  );
}

export default Items;
