import Navbar from "../../components/NavBar";
import Hero from "./components/Hero";
import Items from "./components/ItemsSection";
import Footer from "./components/Footer";

function Product() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Items />
      <Footer />
    </div>
  );
}

export default Product;
