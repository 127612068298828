import * as React from "react";

import axios from "axios";
import { useState, useEffect } from "react";

const FileUploader = (props) => {
  const { value, onChange } = props;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setSelected(value);
    return () => {};
  }, [value]);
  const onFileChange = async (e) => {
    if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
      alert("The file uploaded need to be -jpeg,jpg,png");
      return false;
    }
    setLoading(true);
    var file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const customAxios = axios.create({
      baseURL: "https://app.salespoint.com.mm/",
    });
    try {
      const { data } = await customAxios.post("api/file/screenshot/", formData);
      onChange(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value ? (
        <img
          style={{
            objectFit: "cover",
            borderRadius: "12px",
          }}
          src={value}
          alt=""
          srcSet=""
        />
      ) : (
        <input
          style={{
            // position: "absolute",
            height: " 100%",
            width: "100%",
            opacity: 1,
          }}
          type="file"
          accept="image/*"
          className="uploader-file-input pointer"
          onChange={onFileChange}
          name=""
        />
      )}
    </div>
  );
};
export default FileUploader;
