export const INITIAL_ORDER = {
  customer_gender: "Other",
  customer_name: "",
  delivery_company_name: "",
  delivery_date: "",
  discount: "0",
  discount_percentage: "0",
  discount_reason: "",
  discount_type: "Amount",
  due_amount: "0",
  email: "",
  extra_fees: [],
  is_fulfilled: false,
  location: 1,
  location_name: "",
  note: "",
  order_address: { state: "", city: "", address: "", map: "" },
  order_no: "",
  paid_amount: "0",
  password: "",
  password_confirm: "",
  payment_method: "",
  payment_status: "Unpaid",
  payments: [],
  phone: "",
  products: [],
  screenshot: "",
  selected_payment_method: {
    name: "",
    id: "",
    account_holder_name: "",
    account_number: "",
    description: "",
    store: "",
  },
  shipping_address: [],
  status: "Pending",
  subtotal: "",
  tax: 0,
  tags: [],
  tax_rate: 0,
  timelines: [],
  total: 0,
  username: "",
};
