import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import axios from "axios";
import { API_END_POINT } from "../../constant/data";
import { ClipLoader } from "react-spinners";
import { fCurrencyMMK } from "../../utils/FormatNumber";
import FileUploader from "../../components/media/Upload";
import { useNavigate } from "react-router-dom";
import { INITIAL_ORDER } from "../../@types/order";
import { createNewOrder } from "../../components/order/order";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import useLocales from "../../hook/uselocales";
import { getAuth } from "../../components/auth/getAuth";
import login from "../../components/auth/login";

function MobileBanking(props) {
  const { state } = props;
  const { translate } = useLocales();
  const navigate = useNavigate();
  const items = useSelector((state) => state.cart.items);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [screenshot, setScreenshot] = useState("");
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [isLogin, setIsLogin] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  const isDisable = screenshot !== "" && isCheck;

  const [localCustomer, setLocalCustomer] = useState(
    localStorage.getItem("customer")
  );

  const [productData, setProductData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    phone: "",
    address: "",
    state: "",
    city: "",
    map: "",
  });

  useEffect(() => {
    const product = JSON.parse(localStorage.getItem("product"));
    let totalAmountArrays = [];
    product.map((product, index) => {
      totalAmountArrays.push(product.subtotal);
    });
    setTotalAmount(
      totalAmountArrays.reduce(
        (partialSum, a) => Number(partialSum) + Number(a),
        0
      )
    );
  }, []);

  useEffect(() => {
    if (state !== null) {
      setShippingAddress((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.name = state.customer.name;
        newState.phone = state.customer.phone_number;
        newState.address = state.orderAddress.address;
        newState.state = state.orderAddress.state;
        newState.city = state.orderAddress.city;
        return newState;
      });

      setOrder((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.order_address = state?.orderAddress;
        newState.shipping_address = shippingAddress;
        newState.customer_name = state.customer.name;
        newState.due_amount = totalAmount;
        newState.email = state.customer.email;
        newState.note = "";
        newState.password = state.customer.password;
        newState.password_confirm = state.customer.password;
        newState.screenshot = screenshot;
        newState.phone = state.customer.phone_number;
        newState.products = state.selectedProduct;
        newState.subtotal = totalAmount;
        newState.total = totalAmount;
        newState.selected_payment_method = selectedPayment;
        newState.username =
          state.customer.name.toLowerCase().replaceAll(" ", "_") +
          "_" +
          uuidv4();
        return newState;
      });
    } else {
      setIsLogin(true);
    }
  }, [totalAmount, paymentMethods, state, screenshot]);

  useEffect(() => {
    if (isLogin && localCustomer) {
      setShippingAddress((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.name = localCustomer.name;
        newState.phone = localCustomer.phone;
        newState.address = localCustomer.customer_addresses[0].address;
        newState.state = localCustomer.customer_addresses[0].state;
        newState.city = localCustomer.customer_addresses[0].city;
        return newState;
      });

      setOrder((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.order_address = shippingAddress;
        newState.shipping_address = shippingAddress;
        newState.customer_name = localCustomer?.name;
        newState.due_amount = totalAmount;
        newState.email = localCustomer?.email;
        newState.note = "";
        newState.screenshot = screenshot;
        newState.phone = localCustomer?.phone;
        newState.products = productData;
        newState.subtotal = totalAmount;
        newState.total = totalAmount;
        newState.selected_payment_method = selectedPayment;
        newState.username = localCustomer?.user?.username;
        return newState;
      });
    }
  }, [, totalAmount, isLogin, localCustomer, paymentMethods, screenshot]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setLoading(true);
      const response = await axios.get(`${API_END_POINT}web/payment-methods/`);
      setPaymentMethods(response.data);
      setSelectedPayment(response.data[0]);
      setLoading(false);
    };
    const customer = JSON.parse(localStorage.getItem("customer"));
    const product = JSON.parse(localStorage.getItem("product"));
    setLocalCustomer(customer);
    setProductData(product);
    fetchPaymentMethods();
  }, []);

  const CreateOrder = () => {
    setLoading(true);
    createNewOrder(order)
      .then((data) => {
        if (isLogin === false) {
          login({
            email: state.customer.email,
            password: state.customer.password,
          });
        }
        setLoading(false);
        localStorage.removeItem("product");
        navigate("/confirm-order");
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <>
      <section className=" flex flex-col  mt-6 gap-5">
        <div className="flex flex-col  gap-5">
          <div method="" encType="multipart/form-data">
            <div className="flex flex-wrap gap-8">
              {loading ? (
                <div className=" w-full text-center">
                  <ClipLoader color="#ed1b24" size={50} />
                </div>
              ) : (
                paymentMethods.map((payment) => {
                  let selected = payment.id === selectedPayment.id;
                  if (payment.name === "Cash") {
                    return "";
                  }
                  return (
                    <div
                      key={payment.name}
                      className="border-4 border-gray-400 rounded-2xl"
                      style={{
                        borderColor: selected ? "red" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedPayment(payment);
                      }}
                    >
                      <p className=" py-4 px-8">{payment.name}</p>
                    </div>
                  );
                })
              )}
            </div>
            <div className="flex flex-col">
              <div className="w-[80%] flex flex-wrap justify-between font-Cushy_W01 text-[var(--text-color)] text-sm  my-5">
                {/* need condition for mobileBanking */}
                <p>{translate("accountName")} :</p>
                <p>{selectedPayment.account_holder_name}</p>
              </div>
              <div className="w-[80%] flex flex-wrap justify-between font-Cushy_W01 text-[var(--text-color)] text-sm  my-5">
                {/* need condition for mobileBanking */}
                <p>{translate("accountNumber")} :</p>
                <p>{selectedPayment.account_number}</p>
              </div>

              <div className="w-[80%] flex flex-wrap justify-between font-Cushy_W01 text-[var(--text-color)] text-sm  my-5">
                <p>{translate("orderTotal")}</p>
                <p> {fCurrencyMMK(totalAmount)} MMK</p>
              </div>
            </div>
            <div className="flex flex-col">
              <h3 className="font-Cushy text-[var(--text-color)] mt-3 mb-3">
                {translate("addPaymentScreenshot")}
              </h3>
              <FileUploader
                value={screenshot}
                onChange={({ full_url }) => {
                  setScreenshot(full_url);
                }}
              />
              <p className="font-Cushy text-[#696969] py-4">
                {translate("addImageOrPasteHere")}
              </p>
              <p className="font-Cushy_W01 text-[var(--primary-color)] py-6">
                <span className="font-PepMyanmar">
                  {translate("delifreeabout")}
                </span>
              </p>
            </div>
            <div className="flex pb-6">
              <input
                type="checkbox"
                value={isCheck}
                onClick={() => {
                  setIsCheck(!isCheck);
                }}
                name="remember-me"
                id="remember-me"
                className="custom-form-checkbox text-indigo-600 h-4 w-4"
                required
              />
              <label
                htmlFor="remember-me"
                className="font-Cushy_W01 text-[#696969] px-2"
              >
                {translate("termAndPermission")}
              </label>
            </div>
            <div className="w-full flex">
              <p
                onClick={() => {
                  CreateOrder();
                }}
                style={{
                  cursor: loading ? "" : "pointer",
                  backgroundColor: isDisable ? "red" : "#000000b8",
                }}
                className="my-4 py-4 px-10 font-Cushy_W01 text-white text-sm  rounded-xl"
              >
                {loading ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  <>{translate("makePayment")}</>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MobileBanking;
