import { isValidToken, setToken } from "./jwt";
import { logout } from "./logout";

export const setOldToken = async () => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    if (isValidToken(accessToken)) {
      setToken(accessToken);
    } else {
      logout();
    }
  }
};
