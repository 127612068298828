import React, { useState, useEffect } from "react";
import useLocales from "../../hook/uselocales";

function Language() {
  const { allLangs, onChangeLang, currentLang } = useLocales();
  const [currentLanguage, setCurrentLanguage] = useState(currentLang.value);

  return (
    <div>
      <p
        style={{
          width: "100px",
          display: "flex",
          color: "#919EAB",
          marginRight: "1rem",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: currentLanguage === "mm" ? "#FF3939" : " #919EAB",
            cursor: "pointer",
          }}
          component="span"
          onClick={(e) => {
            setCurrentLanguage("mm");
            onChangeLang("mm");
          }}
        >
          MM
        </p>{" "}
        |{" "}
        <p
          style={{
            color: currentLanguage === "en" ? "#FF3939" : " #919EAB",
            cursor: "pointer",
          }}
          component="span"
          onClick={(e) => {
            setCurrentLanguage("en");
            onChangeLang("en");
          }}
        >
          EN
        </p>
      </p>
    </div>
  );
}

export default Language;
