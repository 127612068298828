import React from "react";
import { Link } from "react-router-dom";
import useLocales from "../hook/uselocales";
import Language from "./navbar/language";
import { useSelector } from "react-redux";

function NavBar() {
  const selectedProduct = useSelector((cart) => cart.cart.items);
  const { translate } = useLocales();
  const customer = JSON.parse(localStorage.getItem("customer"));

  return (
    <nav className="w-full min-h-[90px] flex justify-between items-center z-10 px-5">
      <Link to="/">
        <img src="/media/images/pep/web-img/logo.png" alt="logo" />
      </Link>
      <Link
        to="/add-to-cart"
        className="flex justify-between items-center relative"
      >
        <img src="/media/images/pep/web-img/Bag.png" alt="bag-icon" />
        {selectedProduct.length > 0 ? (
          <div className="flex align-middle justify-center absolute -top-4 bg-red-600 w-8 h-8 -right-4 rounded-2xl">
            <p className=" mt-1 text-white">{selectedProduct.length}</p>
          </div>
        ) : (
          ""
        )}
        <p className="text-sm text-[var(--text-color)] px-5">
          {translate("shoppingCart")}
        </p>
      </Link>
      <Link
        to={customer ? "/account-detail" : "/login"}
        className="flex justify-between items-center"
      >
        <img src="/media/images/pep/web-img/Profile.png" alt="profile-icon" />
        <p className="text-sm text-[var(--text-color)] px-5">
          {customer ? customer.name : translate("loginAndRegister")}
        </p>
      </Link>
      <Language />
      {/* <div>
        <p>
          <span className="text-[var(--primary-color)] font-Cushy font-semibold px-2">
            EN
          </span>{" "}
          |<span className="font-Cushy_W01 px-2">MM</span>
        </p>
      </div> */}
    </nav>
  );
}

export default NavBar;
