import axios from "axios";
import { MAIN_API_END_POINT } from "../../constant/data";

export const getAuth = async () => {
  try {
    const response = await axios.get(`${MAIN_API_END_POINT}web/auth/me/`);
    const customerResponse = await axios.get(
      `${MAIN_API_END_POINT}web/customer/detail/?email=${response.data.email}`
    );
    localStorage.setItem("customer", JSON.stringify(customerResponse.data));
    localStorage.setItem("store", JSON.stringify(response.data));

    // window.location.reload();
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  }
};
