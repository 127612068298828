import { useState } from "react";
import login from "../../components/auth/login";
import { getAuth } from "../../components/auth/getAuth";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import useLocales from "../../hook/uselocales";

function ReturnCustomer() {
  const navigate = useNavigate();
  var Buffer = require("buffer/").Buffer;
  const { translate } = useLocales();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    const buf = Buffer.from(password);
    const pass = buf.toString("base64");
    // Need to Use Pass
    login({ email, password })
      .then((_res) => {
        getAuth();
        navigate("/payment-details");
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.detail, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <div className="w-full flex flex-col items-center mt-6 gap-16">
        <div className="w-full flex justify-center items-center">
          <div className="w-[70vw] flex flex-col justify-center gap-5">
            <div className="mb-2">
              <div className="grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block accDetail-form-label">
                    {translate("email")}
                  </label>
                  <div className="mt-2">
                    <input
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      type="text"
                      name="email"
                      id="email"
                      placeholder={translate("email")}
                      required
                      className="block w-full rounded-md border border-[var(--text-color)] py-2 px-4 text-[var(--text-color)] placeholder:font-Cushy_W01 placeholder:text-[var(--text-color)] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block accDetail-form-label"
                  >
                    {translate("password")}
                  </label>
                  <div className="mt-2">
                    <input
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      type="password"
                      name="password"
                      id="password"
                      placeholder={translate("password")}
                      required
                      className="block w-full rounded-md border border-[var(--textcolor)] border-opacity-100 py-2 px-4 text-[var(--text-color)] placeholder:font-Cushy_W01 placeholder:text-[var(--text-color)] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Link add */}
            <p className="font-Cushy_W01 text-blue-700">Forget Password</p>{" "}
            <div className="w-full flex justify-center mt-14">
              <p
                onClick={() => {
                  handleLogin();
                }}
                className=" cursor-pointer my-4 py-4 px-16 font-Cushy_W01 text-white text-sm bg-[var(--primary-color)] rounded-xl"
              >
                {translate("continue")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReturnCustomer;
