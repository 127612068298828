// export const API_END_POINT = "https://pep-myanmar.salespoint.com.mm/api/";
// export const MEDIA_URL = "https://pep-myanmar.salespoint.com.mm";
// export const MAIN_API_END_POINT = "https://app.salespoint.com.mm/api/";

export const API_END_POINT = "https://store.pepmyanmar.com/api/";
export const MEDIA_URL = "https://store.pepmyanmar.com";
export const MAIN_API_END_POINT = "https://app.salespoint.com.mm/api/";

// export const API_END_POINT = "https://pep-myanmar.axiomapps.net/api/";
// export const MEDIA_URL = "https://pep-myanmar.axiomapps.net/";
// export const MAIN_API_END_POINT = "https://app.axiomapps.net/api/";
