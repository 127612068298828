// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: "EN",
    value: "en",
  },
  {
    label: "MM",
    value: "mm",
  },
];

export const defaultLang = allLangs[0]; // English
