import axios from "axios";
import { API_END_POINT } from "../../constant/data";

export const createNewOrder = async (order) => {
  try {
    const { data } = await axios.post(`${API_END_POINT}web/order/`, {
      data: order,
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
