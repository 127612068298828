import NavBar from "../../../components/NavBar";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../components/auth/logout";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_END_POINT, MAIN_API_END_POINT } from "../../../constant/data";
import moment from "moment";
import useLocales from "../../../hook/uselocales";

function OrderHistory() {
  const navigate = useNavigate();
  const customer = JSON.parse(localStorage.getItem("customer"));
  const [orders, setOrders] = useState([]);
  const { translate } = useLocales();

  useEffect(() => {
    const fetchCustomerOrder = async () => {
      const response = await axios.get(
        `${MAIN_API_END_POINT}web/order/customer/list/?customer_id=${customer.id}`
      );
      setOrders(response.data);
    };
    fetchCustomerOrder();
  }, []);

  return (
    <>
      <NavBar />
      <section className="flex gap-x-3">
        <div className="flex flex-col justify-between w-1/5 p-8 profile-menu">
          <div>
            <Link to="/account-detail">
              <h3 className="acc-menu">{translate("accountDetials")}</h3>
            </Link>
            <a href="/order-history">
              <h3 className="acc-menu">{translate("ordersHistory")}</h3>
            </a>
            <h3
              onClick={() => {
                logout();
                navigate("/");
              }}
              className="acc-menu cursor-pointer"
            >
              {translate("logout")}
            </h3>
          </div>
          <img
            src="/media/images/pep/web-img/Group.png"
            className="w-48"
            alt="cow"
          />
        </div>
        <div className="w-full pr-10 pt-2">
          <h2 className="header-default">{translate("myOrders")}</h2>

          <div className="bg-[var(--primary-color)] mt-10 rounded-t-lg">
            <ul className="text-white font-[Cushy] flex flex-wrap justify-between items-center p-10">
              <li>{translate("invoiceno")}</li>
              <li>{translate("date")}</li>
              <li>{translate("order")}</li>
              <li>{translate("status")}</li>
              <li>{translate("csName")}</li>
            </ul>
          </div>
          {orders.map((order, index) => {
            let textColor;
            if (order.payment_status === "Paid") {
              textColor = "#4AEB47";
            } else if (order.payment_status === "Partially Paid") {
              textColor = "#B78103";
            } else {
              textColor = "#ED1B24";
            }
            return (
              <ul
                key={index}
                className="font-[Cushy] flex flex-wrap justify-between items-center p-10"
              >
                <li>{order.id}</li>
                <li>{moment(order.created_at).format("DD.MM.YYYY")}</li>
                <li>{order.order_no}</li>
                <li style={{ color: textColor }}>
                  {translate(order.payment_status)}
                </li>
                <li>{order.customer_name}</li>
              </ul>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default OrderHistory;
