import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import Cash from "./Cash";
import MobileBanking from "./MobileBanking";
import { useLocation } from "react-router-dom";
import useLocales from "../../hook/uselocales";

const BillingDetail = () => {
  const { translate } = useLocales();
  const [payCash, setPayCash] = useState(true);
  const { state } = useLocation();
  return (
    <>
      <NavBar />
      <section className="w-screen flex flex-col items-center mt-6 gap-5">
        <div className="mb-6">
          <ul className="flex flex-wrap justify-center items-center gap-5 font-Cushy_W01 text-[var(--text-color)]">
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("checkoutOptions")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span
                style={{ border: "2px solid red" }}
                className="block w-6 h-6 rounded-full bg-[#F0F0F0]"
              ></span>{" "}
              <p>{translate("billingDetail")}</p>
            </li>
            <span className="block w-32 h-[1px] bg-[#696969]"></span>
            <li className="flex justify-center items-center gap-3 ">
              <span className="block w-6 h-6 rounded-full bg-[#F0F0F0]"></span>{" "}
              <p>{translate("confirmOrder")}</p>
            </li>
          </ul>
        </div>

        <div className="w-full flex flex-col justify-center items-center gap-5">
          <h1 className="header-default">{translate("selectPaymentMethod")}</h1>
          <form action="" className="w-[35%]">
            <div className="w-full flex justify-center items-center">
              <div className="font-Cushy_W01 text-[var(--text-color)] flex justify-center items-center gap-20 mb-6">
                <div className="flex items-center gap-2">
                  <input
                    defaultChecked={payCash}
                    type="radio"
                    id="yes-btn"
                    name="subscribe"
                    className="custom-registerform-radio"
                    required
                    onClick={() => {
                      setPayCash(true);
                    }}
                  />
                  <label htmlFor="yes-btn">{translate("cash")}</label>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    defaultChecked={!payCash}
                    type="radio"
                    id="no-btn"
                    name="subscribe"
                    className="custom-registerform-radio"
                    required
                    onClick={() => {
                      setPayCash(false);
                    }}
                  />
                  <label htmlFor="no-btn">{translate("mobileBanking")}</label>
                </div>
              </div>
            </div>
            {payCash ? <Cash state={state} /> : <MobileBanking state={state} />}
          </form>
        </div>
      </section>
    </>
  );
};

export default BillingDetail;
