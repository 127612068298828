import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { Input } from "@material-tailwind/react";
import address from "../../@types/address";
import { useSelector } from "react-redux";
import useLocales from "../../hook/uselocales";

function RegisterAcc() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const selectedProduct = useSelector((cart) => cart.cart.items);
  const [showPass, setShowPass] = useState(false);
  const [orderAddress, setOrderAddress] = useState({
    address: "",
    city: "",
    default: true,
    map: "",
    state: "",
  });

  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
  });

  return (
    <>
      <section className="w-screen flex flex-col items-center mt-6 gap-5">
        <div className="w-full flex ">
          <div className="w-[70vw] flex flex-col justify-center">
            <div className="mb-5">
              <div className="grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="name" className="block accDetail-form-label">
                    {translate("yourName")}
                  </label>
                  <div className="mt-2">
                    <input
                      value={customer.name}
                      onChange={(e) => {
                        setCustomer({
                          ...customer,
                          name: e.target.value,
                        });
                      }}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={translate("yourName")}
                      autoComplete="given-name"
                      required
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block accDetail-form-label">
                    {translate("email")}
                  </label>
                  <div className="mt-2">
                    <input
                      value={customer.email}
                      onChange={(e) => {
                        setCustomer({
                          ...customer,
                          email: e.target.value,
                        });
                      }}
                      type="email"
                      name="email"
                      id="name"
                      placeholder={translate("email")}
                      autoComplete="given-name"
                      required
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="phone" className="block accDetail-form-label">
                    {translate("phno")}
                  </label>
                  <div className="mt-2">
                    <input
                      value={customer.phone_number}
                      onChange={(e) => {
                        setCustomer({
                          ...customer,
                          phone_number: e.target.value,
                        });
                      }}
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="09"
                      autoComplete="given-name"
                      required
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block accDetail-form-label"
                  >
                    {translate("password")}
                  </label>
                  <div className="mt-2 relative">
                    <i
                      className={showPass ? "fa fa-eye" : "fa fa-eye-slash"}
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "15px",
                        cursor: "pointer",
                      }}
                      aria-hidden="true"
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    ></i>
                    <input
                      value={customer.password}
                      onChange={(e) => {
                        setCustomer({
                          ...customer,
                          password: e.target.value,
                        });
                      }}
                      type={showPass ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder={translate("password")}
                      autoComplete="given-name"
                      required
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="state" className="block accDetail-form-label">
                    {translate("state")}
                  </label>
                  <div className="mt-2">
                    <select
                      value={orderAddress.state}
                      name="state"
                      id="state"
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setOrderAddress({
                          ...orderAddress,
                          state: e.target.value,
                        });
                      }}
                    >
                      {address.map((val, index) => (
                        <option key={`${index}-state`} value={val.value}>
                          {val.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block accDetail-form-label"
                  >
                    {translate("country")}
                  </label>
                  <div className="mt-2">
                    <select
                      name="country"
                      id="country"
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                    >
                      <option defaultChecked>Myanmar</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block accDetail-form-label">
                    {translate("city")}
                  </label>
                  <div className="mt-2">
                    <select
                      value={orderAddress.city}
                      name="city"
                      id="city"
                      className="block w-full rounded-md border-0 py-2.5 px-4 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setOrderAddress({
                          ...orderAddress,
                          city: e.target.value,
                        });
                      }}
                    >
                      {address
                        .filter(
                          (state) => state.value === orderAddress.state
                        )[0]
                        ?.children.map((val, index) => (
                          <option key={`${val.value}-city`} value={val.value}>
                            {val.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-[50%] sm:col-span-3 mt-9">
                <label htmlFor="address" className="block accDetail-form-label">
                  {translate("detailAddress")}
                </label>
                <div className="mt-2">
                  <textarea
                    value={orderAddress.address}
                    onChange={(e) => {
                      setOrderAddress({
                        ...orderAddress,
                        address: e.target.value,
                      });
                    }}
                    name="address"
                    id="address"
                    cols="20"
                    rows="5"
                    placeholder="No.9,Yakin Street"
                    required
                    className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                className="custom-form-checkbox text-indigo-600 h-4 w-4"
                required
              />
              <label
                htmlFor="remember-me"
                className="font-Cushy_W01 text-[#696969] px-2"
              >
                {translate("myAddressAndDeliveryAddressAreSame")}
              </label>
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={() => {
                  navigate("/payment-details", {
                    state: { customer, orderAddress, selectedProduct },
                  });
                }}
                type="submit"
                className="my-4 py-4 px-16 font-Cushy_W01 text-white text-sm bg-[var(--primary-color)] rounded-xl"
              >
                {translate("continue")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterAcc;
