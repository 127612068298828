const address = [
  {
    label: "",
    children: [],
  },
  {
    label: "Yangon (ရန်ကုန်တိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Botataung (ဗိုလ်တထောင်)",
      },
      {
        label: "Dagon Seikkan (ဒဂုံဆိပ်ကမ်း)",
      },
      {
        label: "East Dagon (အရှေ့ဒဂုံ)",
      },
      {
        label: "North Dagon (မြောက်ဒဂုံ)",
      },
      {
        label: "South Dagon (တောင်ဒဂုံ)",
      },
      {
        label: "North Okkalapa (မြောက်ဥက္ကလာပ)",
      },
      {
        label: "South Okkalapa (တောင်ဥက္ကလာပ)",
      },
      {
        label: "Pazundaung (ပုဇွန်တောင်)",
      },
      {
        label: "Thingangyun (သင်္ဃန်းကျွန်း)",
      },
      {
        label: "Dawbon (ဒေါပုံ)",
      },
      {
        label: "Mingalar Taungnyunt (မင်္ဂလာတောင်ညွှန့်)",
      },
      {
        label: "Tamwe (တာမွေ)",
      },
      {
        label: "Thaketa (သာကေတ)",
      },
      {
        label: "Yankin (ရန်ကင်း)",
      },
      {
        label: "Hlaing Tharyar (လှိုင်သာယာ)",
      },
      {
        label: "Insein (အင်းစိန်)",
      },
      {
        label: "Mingalardon (မင်္ဂလာဒုံ)",
      },
      {
        label: "Shwepyitha (ရွှေပြည်သာ)",
      },
      {
        label: "Hlegu (လှည်းကူး)",
      },
      {
        label: "Hmawbi (မှော်ဘီ)",
      },
      {
        label: "Htantabin (ထန်းတပင်)",
      },
      {
        label: "Taikkyi (တိုက်ကြီး)",
      },
      {
        label: "Dala (ဒလ)",
      },
      {
        label: "Seikkyi Kanaungto (ဆိပ်ကြီးခနောင်တို)",
      },
      {
        label: "Cocokyun (ကိုကိုးကျွန်း)",
      },
      {
        label: "Kawhmu (ကော့မှူး)",
      },
      {
        label: "Kayan (ခရမ်း)",
      },
      {
        label: "Kungyangon (ကွမ်းခြံကုန်း)",
      },
      {
        label: "Kyauktan (ကျောက်တန်း)",
      },
      {
        label: "Thanlyi (သန်လျင်)",
      },
      {
        label: "Thongwa (သုံးခွ)",
      },
      {
        label: "Thonse (သုံးဆယ်)",
      },
      {
        label: "Twante (တွံတေး)",
      },
      {
        label: "Ahlon (အလုံ)",
      },
      {
        label: "Bahan (ဗဟန်း)",
      },
      {
        label: "Dagon (ဒဂုံ)",
      },
      {
        label: "Kyauktada (ကျောက်တံတား)",
      },
      {
        label: "Kyimyindaing (ကြည့်မြင်တိုင်)",
      },
      {
        label: "Lanmadaw (လမ်းမတော်)",
      },
      {
        label: "Latha (လသာ)",
      },
      {
        label: "Pabedan (ပန်းပဲတန်း)",
      },
      {
        label: "Sanchaung (စမ်းချောင်း)",
      },
      {
        label: "Seikkan (ဆိပ်ကမ်း)",
      },
      {
        label: "Hlaing (လှိုင်)",
      },
      {
        label: "Kamayut (ကမာရွတ်)",
      },
      {
        label: "Mayangon (မရမ်းကုန်း)",
      },
    ],
  },
  {
    label: "Mandalay (မန္တလေးတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Kyaukse (ကျောက်ဆည်မြို့)",
      },
      {
        label: "Taungtha (တောင်သာ)",
      },
      {
        label: "Mandalay (မန္တလေးမြို့)",
      },
      {
        label: "Myittha (မြစ်သားမြို့)",
      },
      {
        label: "Sintgaing (စဉ့်ကိုင်)",
      },
      {
        label: "Tada_U (တံတားဦး)",
      },
      {
        label: "Amarapura (အမရပူရ)",
      },
      {
        label: "Aungmyethazan (အောင်မြေသာစံ)",
      },
      {
        label: "Chanayethazan (ချမ်းအေးသာစံ)",
      },
      {
        label: "Chanmyathazi (ချမ်းမြသာစည်)",
      },
      {
        label: "Mahaaungmye (မဟာအောင်မြေ)",
      },
      {
        label: "Patheingyi (ပုသိမ်ကြီး)",
      },
      {
        label: "Pyigyidagun (ပြည်ကြီးတံခွန်)",
      },
      {
        label: "Mahlaing (မလှိုင်)",
      },
      {
        label: "Meiktila (မိတ္ထီလာ)",
      },
      {
        label: "Thazi (သာစည်)",
      },
      {
        label: "Wundwin (ဝမ်းတွင်း)",
      },
      {
        label: "Myingyan (မြင်းခြံ)",
      },
      {
        label: "Natogyi (နွားထိုးကြီး)",
      },
      {
        label: "Nganzun (ငါန်းဇွန်)",
      },
      {
        label: "Thaungtha (တောင်သာ)",
      },
      {
        label: "Nyaung-U (ညောင်ဦး)",
      },
      {
        label: "Kyaukpadaung (ကျောက်ပန်းတောင်း)",
      },
      {
        label: "Ngathayauk (ငါ့သရောက်)",
      },
      {
        label: "Madaya (မတ္တရာ)",
      },
      {
        label: "Mogok (မိုးကုတ်)",
      },
      {
        label: "Pyinoolwin (ပြင်ဦးလွင်)",
      },
      {
        label: "Singu (စဉ့်ကူ)",
      },
      {
        label: "Thabeikkyin (သပိတ်ကျင်း)",
      },
      {
        label: "Tagaung (တကောင်း)",
      },
      {
        label: "Pyawbwe (ပျော်ဘွယ်)",
      },
      {
        label: "Yamethin (ရမည်းသင်း)",
      },
    ],
  },
  {
    label: "Magway (မကွေးတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Gangaw (ဂန့်ဂေါ)",
      },
      {
        label: "Myit Chay (မြစ်ခြေ)",
      },
      {
        label: "Saw (စော)",
      },
      {
        label: "Tilin (ထီးလင်း)",
      },
      {
        label: "Chauck (ချောက်)",
      },
      {
        label: "Magway (မကွေးမြို့)",
      },
      {
        label: "Natmauk (နတ်မောက်)",
      },
      {
        label: "Taungdwingyi (တောင်တွင်းကြီး)",
      },
      {
        label: "Yenangyaung (ရေနံချောင်း)",
      },
      {
        label: "Minbu (မင်းဘူး)",
      },
      {
        label: "Ngape (ငဖဲ)",
      },
      {
        label: "Pwintbyu (ပွင့်ဖြူ)",
      },
      {
        label: "Salin (စလင်း)",
      },
      {
        label: "Myaing (မြိုင်)",
      },
      {
        label: "Pakokku (ပခုက္ကူ)",
      },
      {
        label: "Pauk (ပေါက်)",
      },
      {
        label: "Seikphyu (ဆိပ်ဖြူ)",
      },
      {
        label: "Yesagyo (ရေစကြို)",
      },
      {
        label: "Aunglan (အောင်လံ)",
      },
      {
        label: "Kamma (ကမ္မ)",
      },
      {
        label: "Mindon (မင်းတုန်း)",
      },
      {
        label: "Minhla (မင်းလှ)",
      },
      {
        label: "Sinbaungwe (ဆင်ပေါင်ဝဲ)",
      },
      {
        label: "Thayet (သရက်)",
      },
    ],
  },
  {
    label: "Naypyidaw (နေပြည်တော်)",
    children: [
      {
        label: "",
      },
      {
        label: "Thar Wut Hti (သာဝတ်ထိ)",
      },
      {
        label: "Dekkhinathiri (ဒက္ခိဏသီရိ)",
      },
      {
        label: "Lewe (လယ်ဝေး)",
      },
      {
        label: "Pyinmana (ပျဉ်းမနား)",
      },
      {
        label: "Zabuthiri (ဇမ္မူသီရိ)",
      },
      {
        label: "Naypyidaw (နေပြည်တော်)",
      },
      {
        label: "Ottarathiri (ဥတ္တရသီရိ)",
      },
      {
        label: "Pobbathiri (ပုပ္ဗသီရိ)",
      },
      {
        label: "Tatkon (တပ်ကုန်း)",
      },
      {
        label: "Zeyarthiri (ဇေယျာသီရိ)",
      },
    ],
  },
  {
    label: "Ayeyarwady (ဧရာဝတီတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Yegyi (ရေကြည်)",
      },
      {
        label: "Myaungmya (မြောင်းမြ)",
      },
      {
        label: "Hinthada (ဟင်္သာတ)",
      },
      {
        label: "Lemyethna (လေးမျက်နှာ)",
      },
      {
        label: "Zalun (ဇလွန်)",
      },
      {
        label: "Ingapu (အင်္ဂပူ)",
      },
      {
        label: "Kyangin (ကြံခင်း)",
      },
      {
        label: "Myanaung (မြောင်းမြ)",
      },
      {
        label: "Labutta (လပွတ္တာ)",
      },
      {
        label: "Mawlamyinegyun (မော်လမြိုင်ကျွန်း)",
      },
      {
        label: "Pyinsalu (ပြင်စလူခွဲ)",
      },
      {
        label: "Danuphyu (ဓနုဖြူ)",
      },
      {
        label: "Ma-ubin (မအူပင်)",
      },
      {
        label: "Nyaungdon (ညောင်တုန်း)",
      },
      {
        label: "Pantanaw (ပန်းတနော်)",
      },
      {
        label: "Einme (အိမ်မဲ)",
      },
      {
        label: "Wakema (ဝါးခယ်မ)",
      },
      {
        label: "Kangyidaunk (ကန်ကြီးတောင့်)",
      },
      {
        label: "Ngapudaw (ငပုတော)",
      },
      {
        label: "Pathein (ပုသိမ်မြို့)",
      },
      {
        label: "Thabaung (သာပေါင်း)",
      },
      {
        label: "Kyaunggon (ကျောင်းကုန်း)",
      },
      {
        label: "Kyonpyaw (ကျုံပျော်)",
      },
      {
        label: "Yekyi (ရေကြီး)",
      },
      {
        label: "Ngayokaung (ငရုပ်ကောင်း)",
      },
      {
        label: "Hainggyikyun (ဟိုင်းကြီးကျွန်း)",
      },
      {
        label: "Shwethaungyan (ရွှေသာယန်ကျေးရွာအုပ်ချုပ်ရေးမှူး)",
      },
      {
        label: "Ngwehsaung (ငွေဆောင်)",
      },
      {
        label: "Chaungthar (ချောင်းသာ)",
      },
      {
        label: "Ngathaingchaung (ငသိုင်းချောင်းခွဲ)",
      },
      {
        label: "Bogale (ဘိုကလေး)",
      },
      {
        label: "Dedaye (ဒေးဒရဲ)",
      },
      {
        label: "Kyaiklat (ကျိုက်လတ်)",
      },
      {
        label: "Pyapon (ဖျာပုံ)",
      },
      {
        label: "Ahmar (အမာခွဲ)",
      },
    ],
  },
  {
    label: "Bago (ပဲခူးတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Thar Ga Ya (သာဂရ)",
      },
      {
        label: "Bago (ပဲခူး)",
      },
      {
        label: "Daik-U (ဒိုက်ဦး)",
      },
      {
        label: "Kawa (ကဝ)",
      },
      {
        label: "Nyaunglebin (ညောင်လေးပင်)",
      },
      {
        label: "Shwegyin (ရွှေကျင်)",
      },
      {
        label: "Thanatpin (သနပ္်ပင်)",
      },
      {
        label: "Waw (ဝေါ)",
      },
      {
        label: "Kyauktaga (ကျောက်တံခါး)",
      },
      {
        label: "Hpayargyi (ဘုရားကြီး)",
      },
      {
        label: "Pyuntaza (ပြွန်တန်ဆာခွဲ)",
      },
      {
        label: "Madauk (မဒေါက်)",
      },
      {
        label: "Penwegon (ပဲနွယ်ကုန်းခွဲ)",
      },
      {
        label: "Aungmyin (အောင်မြေ)",
      },
      {
        label: "Kyaukkyi (ကျောက်ကြီး)",
      },
      {
        label: "Oktwin (အုတ်တွင်း)",
      },
      {
        label: "Pyu (ပျူ)",
      },
      {
        label: "Tantabin (ထန်းတပင်)",
      },
      {
        label: "Taungoo (တောင်ငူ)",
      },
      {
        label: "Yedashe (ရေတာရှည်)",
      },
      {
        label: "Kanyutkwin (ကညွတ်ကွင်း)",
      },
      {
        label: "Nyaungbinthar (ညောင်ပင်သာ)",
      },
      {
        label: "Kywebwe (ကျွဲပွဲ)",
      },
      {
        label: "Padaung (ပန်းတောင်း)",
      },
      {
        label: "Paukkaung (ပေါက်ခေါင်း)",
      },
      {
        label: "Paungde (ပေါင်းတည်)",
      },
      {
        label: "Pyay (ပြည်)",
      },
      {
        label: "Shwedaung (ရွှေတောင်)",
      },
      {
        label: "Thegon (သဲကုန်း)",
      },
      {
        label: "Innma (အင်းမ)",
      },
      {
        label: "Padigone (ပုတီးကုန်း)",
      },
      {
        label: "Sinmezwe (ဆင်မြီးဆွဲ)",
      },
      {
        label: "Paungdale (ပေါင်းတလည်)",
      },
      {
        label: "Gyobingauk (ကြို့ပင်ကောက်)",
      },
      {
        label: "Letpadan (လက်ပတန်းမြို့)",
      },
      {
        label: "Minhla (မင်းလှ)",
      },
      {
        label: "Monyo (မိုးညို)",
      },
      {
        label: "Okpho (အုတ်ဖို)",
      },
      {
        label: "Tharrawaddy (သာရ၀တီ)",
      },
      {
        label: "Nattalin (နတ်တလင်း)",
      },
      {
        label: "Zigon (ဇီးကုန်း)",
      },
      {
        label: "Thonze (သုံးဆယ်)",
      },
      {
        label: "Ooethegone (အိုးသည်ကုန်း)",
      },
      {
        label: "Sitkwin (စစ်ကွင်း)",
      },
      {
        label: "Tapun (တာပွန်)",
      },
    ],
  },
  {
    label: "Sagaing (စစ်ကိုင်းတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Hkamti (ခန္တီး)",
      },
      {
        label: "Homalin (ဟုမ္မလင်း)",
      },
      {
        label: "Leshi (လေရှီး)",
      },
      {
        label: "Nanyun (နန်းယွန်း)",
      },
      {
        label: "Donhee (ဒုံဟီး)",
      },
      {
        label: "Htanparkway (ထန်ပါခွေ)",
      },
      {
        label: "Pansaung (ပန်ဆောင်)",
      },
      {
        label: "Kanbalu (ကန့်ဘလူ)",
      },
      {
        label: "Kyunhla (ကျွန်းလှ)",
      },
      {
        label: "Taze (တန့်ဆည်)",
      },
      {
        label: "Ye-U (ရေဦး)",
      },
      {
        label: "Kale (ကလေး)",
      },
      {
        label: "Kalewa (ကလေးဝ)",
      },
      {
        label: "Mingin (မင်းကင်း)",
      },
      {
        label: "Banmauk (ဗန်းမောက်)",
      },
      {
        label: "Indaw (အင်းတော်)",
      },
      {
        label: "Katha (ကသာ)",
      },
      {
        label: "Kawlin (ကောလင်)",
      },
      {
        label: "Pinlebu (ပင်လည်ဘူး)",
      },
      {
        label: "Tigyaing (ထီးချိုင့်)",
      },
      {
        label: "Wuntho (ဝန်းသို)",
      },
      {
        label: "Mawlaik (မော်လိုက်)",
      },
      {
        label: "Paungbyin (ဖေါင်းပြင်)",
      },
      {
        label: "Ayadaw (အရာတော်)",
      },
      {
        label: "Budalin (ဘုတလင်)",
      },
      {
        label: "Chaung-U (ချောင်းဦး)",
      },
      {
        label: "Monywa (မုံရွာ)",
      },
      {
        label: "Myaung (မြှောင်)",
      },
      {
        label: "Myinmu (မြင်းမူ)",
      },
      {
        label: "Sagaing (စစ်ကိုင်း)",
      },
      {
        label: "Khin-U (ခင်ဦး)",
      },
      {
        label: "Shwebo (ရွှေဘို)",
      },
      {
        label: "Wetlet (ဝက်လက်)",
      },
      {
        label: "Tabayin (ဒီပဲယင်း)",
      },
      {
        label: "Kyaukmyaung (ကျောက်မြောင်း)",
      },
      {
        label: "Tamu (တမူး)",
      },
      {
        label: "Khampat (ခန်းပတ်)",
      },
      {
        label: "Myothit (မြို့သစ်)",
      },
      {
        label: "Kani (ကနီ)",
      },
      {
        label: "Pale (ပုလဲ)",
      },
      {
        label: "Salingyi (ဆားလင်းကြီး)",
      },
      {
        label: "Yinmabin (ယင်းမာပင်)",
      },
    ],
  },
  {
    label: "Tanintharyi (တနင်္သာရီတိုင်း)",
    children: [
      {
        label: "",
      },
      {
        label: "Dawei (ထားဝယ်)",
      },
      {
        label: "Launglon (လောင်းလုံ)",
      },
      {
        label: "Thayetchaung (သရက်ချောင်း)",
      },
      {
        label: "Yebyu (ရေဖြူ)",
      },
      {
        label: "Kaleinaung (ကလိန်အောင်)",
      },
      {
        label: "Myitta (မေတ္တာ)",
      },
      {
        label: "Bokpyin (ဘုတ်ပြင်း)",
      },
      {
        label: "Kawthoung (ကော့သောင်း)",
      },
      {
        label: "Karathuri (ကရသူရိ)",
      },
      {
        label: "Khamaukgyi (ခမောက်ကြီး)",
      },
      {
        label: "Pyigyimandaing (ပြည်ကြီးမဏ္ဍိုင်)",
      },
      {
        label: "Kyunsu (ကျွန်းစု)",
      },
      {
        label: "Myeik (မြိတ်)",
      },
      {
        label: "Palaw (ပုလော)",
      },
      {
        label: "Palauk (ပလောက်)",
      },
    ],
  },
  {
    label: "East Shan State (အရှေ့ရှမ်းပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Kengtung (ကျိုင်းတုံ)",
      },
      {
        label: "Mong Khet (မိုင်းခတ်)",
      },
      {
        label: "Mong La (မိုင်းလား)",
      },
      {
        label: "Mong Yang (မိုင်းယန်း)",
      },
      {
        label: "Mine Pauk (မိုင်းပေါက်)",
      },
      {
        label: "Mong Hpayak (မိုင်းဖြတ်)",
      },
      {
        label: "Mong Yawng (မိုင်းယောင်း)",
      },
      {
        label: "Mong Hsat (မိုင်းဆတ်)",
      },
      {
        label: "Mong Ping (မိုင်းပြင်း)",
      },
      {
        label: "Mong Tong (မိုင်းတုံ)",
      },
      {
        label: "Tachileik (တာချီလိတ်)",
      },
      {
        label: "Minekoke (မိုင်းကုတ်)",
      },
      {
        label: "Ponparkyin (ပုံပါကျင်)",
      },
    ],
  },
  {
    label: "North Shan State (ရှမ်းပြည်နယ်မြောက်ပိုင်း )",
    children: [
      {
        label: "",
      },
      {
        label: "Hsipaw (သီပေါ)",
      },
      {
        label: "Kyaukme (ကျောက်မဲ)",
      },
      {
        label: "Mantong (မန်တုန်)",
      },
      {
        label: "Namhsan (နမ့်ဆန်)",
      },
      {
        label: "Namtu (နမ္မတူ)",
      },
      {
        label: "Nawnghkio (နောင်ချို)",
      },
      {
        label: "Hsenwi (သိန္နီ)",
      },
      {
        label: "Lashio (လားရှိုး)",
      },
      {
        label: "Mongyai (မိုင်းရယ်)",
      },
      {
        label: "Tangyan (တန့်ယန်း)",
      },
      {
        label: "Laukkaing (လောက်ကိုင်)",
      },
      {
        label: "Konkyan (ကုန်းကြမ်း)",
      },
      {
        label: "Kutkai (ကွတ်ခိုင်)",
      },
      {
        label: "Muse (မူဆယ်)",
      },
      {
        label: "Namhkam (နမ့်ခမ်း)",
      },
      {
        label: "Hopang (ဟိုပုန်)",
      },
      {
        label: "Mongmao (မိုင်းမော)",
      },
      {
        label: "Pangwaun (ပန်ဝိုင်)",
      },
      {
        label: "Matman (မက်မန်း)",
      },
      {
        label: "Pangsang (ပန်ဆန်း)",
      },
      {
        label: "Mabein (မဘိမ်း)",
      },
      {
        label: "Mongmit (မိုးမိတ်)",
      },
    ],
  },
  {
    label: "South Shan State (တောင်ပိုင်းရှမ်းပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Langkho (လင်းခေး)",
      },
      {
        label: "Mawkmai (မောက်မယ်)",
      },
      {
        label: "Mong Nai (မိုင်းနိုင်း)",
      },
      {
        label: "Mong Pan (မိုင်းပန်)",
      },
      {
        label: "Kunhing (ကွန်ဟိန်း)",
      },
      {
        label: "Kyethi (ကျေးသီး)",
      },
      {
        label: "Lai-Hka (လဲချား)",
      },
      {
        label: "Loilen (လွိုင်လင်)",
      },
      {
        label: "Mong Hsu (မိုင်းရှူး)",
      },
      {
        label: "Mong Kung (မိုင်းကိုင်)",
      },
      {
        label: "Nansang (နမ့်စန်)",
      },
      {
        label: "Hopong (ဟိုပုံး)",
      },
      {
        label: "Hsi Hseng (ဆီဆိုင်)",
      },
      {
        label: "Kalaw (ကလော)",
      },
      {
        label: "Lawksawk (ရပ်စောက်)",
      },
      {
        label: "Nyaungshwe (ညောင်ရွှေ)",
      },
      {
        label: "Pekon (ဖယ်ခုံ)",
      },
      {
        label: "Pingdaya (ပင်းတယ)",
      },
      {
        label: "Pinlaung (ပင်လောင်း)",
      },
      {
        label: "Taunggyi (တောင်ကြီး)",
      },
      {
        label: "Ywangan (ရှာငံ)",
      },
    ],
  },
  {
    label: "Kachin State (ကချင်ပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Bhamo (ဗန်းမော်)",
      },
      {
        label: "Mansi (မန်စီ)",
      },
      {
        label: "Momauk (မိုးမောက်)",
      },
      {
        label: "Shwegu (ရွှေကူ)",
      },
      {
        label: "Hpakant (ဖားကန့်)",
      },
      {
        label: "Mogaung (မိုးကောင်း)",
      },
      {
        label: "Mohnyin (မိုးညှင်း)",
      },
      {
        label: "Chipwi (ချီဗွေ)",
      },
      {
        label: "Hsawlaw (ဆော့လော)",
      },
      {
        label: "Injangyang (အင်ဂျန်းယန်)",
      },
      {
        label: "Myitkyina (မြစ်ကြီးနားမြို့)",
      },
      {
        label: "Tanai (တနိုင်း)",
      },
      {
        label: "Waingmaw (ဝိုင်းမော်)",
      },
      {
        label: "Kawnglanghpu (ခေါင်လန်ဖူး)",
      },
      {
        label: "Machanbaw (မချမ်းဘေ)",
      },
      {
        label: "Nogmung (နောင်မွန်း)",
      },
      {
        label: "Puta-O (ပူတာအို)",
      },
      {
        label: "Sumprabum (ဆွမ်ပရာဘွမ်)",
      },
    ],
  },
  {
    label: "Kayin State (ကရင်ပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Hlaignbwe (လှိုင်ဘွဲ့)",
      },
      {
        label: "Hpa-an (ဘားအံ)",
      },
      {
        label: "Thandaunggyi (သံတောင်ကြီး)",
      },
      {
        label: "Hpapun (ဖာပွန်)",
      },
      {
        label: "Kawkareik (ကော့ကရိတ်)",
      },
      {
        label: "Kyain Seikgyi (ကြာအင်းဆိပ်ကြီး)",
      },
      {
        label: "Myawaddy (မြဝတီ)",
      },
    ],
  },
  {
    label: "Mon State (မွန်ပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Chaungzon (ချောင်းဆုံ)",
      },
      {
        label: "Kyaikmaraw (ကျိုက်မရော)",
      },
      {
        label: "Mawlamyine (မော်လမြိုင်)",
      },
      {
        label: "Mudon (မုဒုံ)",
      },
      {
        label: "Thanbyuzayat (သံဖြူဇရပ်)",
      },
      {
        label: "Ye (ရေး)",
      },
      {
        label: "Bilin (ဘီလင်း)",
      },
      {
        label: "Kyaikto (ကျိုက်ထို)",
      },
      {
        label: "Paung (ပေါင်)",
      },
      {
        label: "Thaton (သထုံ)",
      },
    ],
  },
  {
    label: "Chin State (ချင်းပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Falam (ဖလမ်း)",
      },
      {
        label: "Tiddim (တီးတိန်)",
      },
      {
        label: "Ton Zang (တွန်းဇံ)",
      },
      {
        label: "Hakha (ဟားခါး)",
      },
      {
        label: "Htantlang (ထန်တလန်)",
      },
      {
        label: "Kanpetlet (ကန်ပက်လက်)",
      },
      {
        label: "Matupi (မတူပီ)",
      },
      {
        label: "Mindat (မင်းတပ်)",
      },
      {
        label: "Paletwa (ပလက်၀)",
      },
    ],
  },
  {
    label: "Rakhine State (ရခိုင်ပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Ann (အမ်း)",
      },
      {
        label: "Kyaukpyu (ကျောက်ဖြူ)",
      },
      {
        label: "Manaung (မာန်အောင်)",
      },
      {
        label: "Ramree (ရမ်းဗြဲ)",
      },
      {
        label: "Buthidaung (ဘူးသီးတောင်)",
      },
      {
        label: "Maungdaw (မောင်တော)",
      },
      {
        label: "Pauktaw (ပေါက်တော)",
      },
      {
        label: "Ponnagyun (ပုဏ္ဏားကျွန်း)",
      },
      {
        label: "Rathedaung (ရသေ့တောင်)",
      },
      {
        label: "Sittwe (စစ်တွေ)",
      },
      {
        label: "Gaw (ဂေါ)",
      },
      {
        label: "Thandwe (သံတွဲ)",
      },
      {
        label: "Toungup (တောင်ကုတ်)",
      },
      {
        label: "Kyauktaw (ကျောက်တော်)",
      },
      {
        label: "Minbya (မင်းပြား)",
      },
      {
        label: "Mrauk-U (မြောက်ဦး)",
      },
      {
        label: "Myebon (မြေပုံ)",
      },
    ],
  },
  {
    label: "Kayah State (ကယားပြည်နယ်)",
    children: [
      {
        label: "",
      },
      {
        label: "Bawlakhe (ဘောလခဲ)",
      },
      {
        label: "Hpasawng (ဖားဆောင်း)",
      },
      {
        label: "Mese (မယ်ဆည်)",
      },
      {
        label: "Demoso (ဒီမောဆို)",
      },
      {
        label: "Hpruso (ဖရူဆို)",
      },
      {
        label: "Loikaw (လွိုင်ကော်)",
      },
      {
        label: "Shadaw (ရှားတော)",
      },
    ],
  },
];
address.forEach((item) => {
  item.value = item.label.replace(/ /g, "_");
  item.children.forEach((child) => {
    child.value = child.label.replace(/ /g, "_");
  });
});
export default address;
