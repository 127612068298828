import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// i18n
import i18n from "./locales/i18n";
import { I18nextProvider } from "react-i18next";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
