import React from "react";

import { MEDIA_URL } from "../../../constant/data";

import { fCurrencyMMK } from "../../../utils/FormatNumber";

import { ClipLoader } from "react-spinners";

import { useDispatch, useSelector } from "react-redux";
import { AddProduct } from "../../../redux/slice/cart";
import useLocales from "../../../hook/uselocales";

function ItemsCards(props) {
  const cart = useSelector((cart) => cart.cart);
  const dispatch = useDispatch();
  const { productList, loading } = props;
  const { translate } = useLocales();

  const addProductToCart = (product) => {
    const hasDiscount = product.sale_price && product.sale_price !== "0";
    let cartProduct = {
      id: product.id,
      name: product.name,
      quantity: 1,
      price: hasDiscount ? product.sale_price : product.regular_price,
      image: product.images[0]?.image || "/media/default.png",
      variation_product: null,
      is_fulfilled: false,
      to_fulfill: "0",
      product: product.id,
      subtotal: parseFloat(
        hasDiscount ? product.sale_price : product.regular_price
      ).toFixed(0),
    };
    dispatch(AddProduct(cartProduct));
  };

  return (
    <div className="w-full px-10">
      <h2 className="mb-10 header-default">{translate("productYouMustTry")}</h2>
      {loading ? (
        <div className=" w-full text-center">
          <ClipLoader color="#ed1b24" size={50} />
        </div>
      ) : productList.length === 0 ? (
        <p className=" text-3xl ">{translate("thereIsNoProduct")}</p>
      ) : (
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-5 gap-y-10">
          {productList.map((product, index) => {
            let productImage;
            if (product.images) {
              product.images[0].image.includes("http")
                ? (productImage = product.images[0].image)
                : (productImage = MEDIA_URL + product.images[0].image);
            } else {
              product.image.includes("http")
                ? (productImage = product.image)
                : (productImage = MEDIA_URL + product.image);
            }

            let selected = false;
            cart.items.map((item, index) => {
              if (item.id === product.id) {
                selected = true;
              }
            });

            return (
              <div
                key={product.name}
                className="bg-[#f4f4f4] rounded-3xl py-6 px-4 relative"
              >
                <img
                  src="/media/images/pep/web-img/simple-icons_happycow.png"
                  className="absolute left-5 top-5"
                  alt="happycow"
                />
                <div className="w-full flex justify-center">
                  <img src={productImage} className="w-56" alt="cream-milk" />
                </div>
                <div className="flex justify-between items-center py-5 xl:px-10">
                  <div className="w-2/3">
                    <h3 className="product-card-subheader">{product.name}</h3>
                    <p className="font-Cushy_W01 text-[#696969] text-sm py-4">
                      {product.sale_price
                        ? fCurrencyMMK(product.sale_price)
                        : fCurrencyMMK(product.regular_price)}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      // dispatch(AddProduct(product));
                      addProductToCart(product);
                    }}
                  >
                    <img
                      src={
                        selected
                          ? "/media/images/pep/web-img/ic_round-added-shopping-cart.png"
                          : "/media/images/pep/web-img/ic_round-add-shopping-cart.png"
                      }
                      style={{
                        backgroundColor: selected ? "" : "#ed1b24",
                        width: selected ? "70px" : "",
                      }}
                      className="rounded-full p-3 lg:p-2 w-12 cursor-pointer"
                      alt="shopping-cart"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ItemsCards;
