import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_END_POINT } from "../../../constant/data";
import useLocales from "../../../hook/uselocales";

function ItemsCategory(props) {
  const { setProductList, setLoading } = props;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [query, setQuery] = useState("All");
  const [categoriesList, setCategoriesList] = useState([]);
  const { translate } = useLocales();

  const FlavourList = [
    {
      name: "All",
      color: "#ED3C1A33",
    },
    {
      name: "Milk",
      color: "#27AE6033",
    },
    {
      name: "Strawbeery",
      color: "#ED1B2433",
    },
    {
      name: "Chocolate",
      color: "#6E434533",
    },
    {
      name: "Banana",
      color: "#FFD76833",
    },
  ];

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const response = await axios.get(
        `${API_END_POINT}web/products/list/?cid=${selectedCategory}&tag=${
          query === "All" ? "" : query
        }`
      );
      setProductList(response.data);
      setLoading(false);
    };
    fetchProduct();
  }, [query, selectedCategory]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await axios.get(`${API_END_POINT}web/category/all/`);
      setCategoriesList(response.data);
    };
    fetchCategories();
  }, []);

  return (
    <div className="xl:w-[35%] lg:w-[50%] rounded-lg shadow-2xl">
      {categoriesList?.length > 0 ? (
        <div className="p-2">
          <h3 className="p-3 product-card-subheader">
            {translate("productCategories")}
          </h3>
          <div className="flex flex-wrap">
            <p
              style={{
                border: selectedCategory === "" ? "2px solid #ed1b24" : "",
              }}
              onClick={() => {
                setSelectedCategory("");
              }}
              className="text-sm p-3 rounded-full bg-[#ED1B2433] flex justify-between items-center m-2 cursor-pointer product-item-category"
            >
              All
              <span
                style={{
                  backgroundColor: selectedCategory === "" ? "#ed1b24" : "",
                }}
                className="w-3 h-3 rounded-full bg-white inline-block mx-2 ball"
              ></span>
            </p>
            {categoriesList
              .sort(function (a, b) {
                return a.id - b.id;
              })
              .map((category, index) => {
                let selected = selectedCategory === category.id;
                return (
                  <p
                    style={{ border: selected ? "2px solid #ed1b24" : "" }}
                    key={category.name}
                    onClick={() => {
                      setSelectedCategory(category.id);
                    }}
                    className="text-sm p-3 rounded-full bg-[#ED1B2433] flex justify-between items-center m-2 cursor-pointer product-item-category"
                  >
                    {category.name}
                    <span
                      style={{ backgroundColor: selected ? "#ed1b24" : "" }}
                      className="w-3 h-3 rounded-full bg-white inline-block mx-2 ball"
                    ></span>
                  </p>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="p-2">
        <h3 className="p-3 product-card-subheader">{translate("favFlavor")}</h3>
        <div className="flex flex-wrap">
          {FlavourList.map((flavour, index) => {
            let selected = query === flavour.name;
            return (
              <p
                key={flavour.name}
                className="text-sm p-3 rounded-full flex justify-between items-center m-2 cursor-pointer product-item-category"
                style={{
                  backgroundColor: flavour.color,
                  border: selected ? "2px solid #ed1b24" : "",
                }}
                onClick={() => {
                  setQuery(flavour.name);
                }}
              >
                {flavour.name}
                <span
                  style={{ backgroundColor: selected ? "#ed1b24" : "" }}
                  className="w-3 h-3 rounded-full bg-white inline-block mx-2 ball"
                ></span>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ItemsCategory;
