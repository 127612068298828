import { useSelector } from "react-redux";
import Navbar from "../../../components/NavBar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_END_POINT, MAIN_API_END_POINT } from "../../../constant/data";
import address from "../../../@types/address";
import { logout } from "../../../components/auth/logout";
import useLocales from "../../../hook/uselocales";

function AccountDetails() {
  const customerData = JSON.parse(localStorage.getItem("customer"));
  const [customer, setCustomer] = useState(customerData);
  const navigate = useNavigate();
  const { translate } = useLocales();

  useEffect(() => {
    setCustomer((preState) => {
      let newState = JSON.parse(JSON.stringify(preState));
      if (newState.customer_addresses.length == 0) {
        newState.customer_addresses.push({
          state: "",
          address: "",
          city: "",
          map: "",
          default: true,
        });
      }
      return newState;
    });
  }, []);

  const UpdateCustomer = async () => {
    try {
      const response = await axios.put(
        `${MAIN_API_END_POINT}web/customer/detail/`,
        { data: customer }
      );
      if (response.status == "201") {
        localStorage.setItem("customer", JSON.stringify(response.data));
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return (
    <>
      <Navbar />
      <section className="flex h-[90vh] m-0 overflow-hidden gap-x-3">
        <div className="w-1/5 flex flex-col justify-between p-8 profile-menu">
          <div>
            <a href="/account-detail">
              <h3 className="acc-menu">{translate("accountDetials")}</h3>
            </a>
            <Link to="/order-history">
              <h3 className="acc-menu">{translate("ordersHistory")}</h3>
            </Link>
            <h3
              onClick={() => {
                logout();
                navigate("/");
              }}
              className="acc-menu cursor-pointer"
            >
              {translate("logout")}
            </h3>
          </div>
          <img
            src="/media/images/pep/web-img/Group.png"
            className="w-48"
            alt="cow"
          />
        </div>
        <div className="w-full px-8 pt-2">
          <h2 className="header-default">{translate("editProfile")}</h2>
          <div className="w-4/6 flex flex-col justify-center">
            <div className="profile-container">
              <img
                src="/media/user.png"
                alt="profile-picture"
              />
              <img
                src="/media/images/pep/web-img/editIcon.png"
                className="edit-icon"
                alt="edit-icon"
              />
            </div>
            <form action="" className="w-full">
              <div className="mb-5">
                <div className="grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="name"
                      className="block accDetail-form-label"
                    >
                      {translate("yourName")}
                    </label>
                    <div className="mt-2">
                      <input
                        value={customer?.name}
                        onChange={(e) => {
                          setCustomer({ ...customer, name: e.target.value });
                        }}
                        type="text"
                        name="name"
                        id="name"
                        placeholder={translate("yourName")}
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block accDetail-form-label"
                    >
                      {translate("email")}
                    </label>
                    <div className="mt-2">
                      <input
                        value={customer?.email}
                        onChange={(e) => {
                          setCustomer({ ...customer, email: e.target.value });
                        }}
                        type="email"
                        name="email"
                        id="name"
                        placeholder={translate("email")}
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone"
                      className="block accDetail-form-label"
                    >
                      {translate("phno")}
                    </label>
                    <div className="mt-2">
                      <input
                        value={customer?.phone}
                        onChange={(e) => {
                          setCustomer({ ...customer, phone: e.target.value });
                        }}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="09"
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="state"
                      className="block accDetail-form-label"
                    >
                      {translate("state")}
                    </label>
                    <div className="mt-2">
                      <select
                        defaultValue={
                          customer.customer_addresses.length > 0
                            ? customer.customer_addresses[0].state
                            : ""
                        }
                        name="state"
                        id="state"
                        className="block w-full rounded-md border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                        onChange={(e) => {
                          setCustomer((state) => {
                            let newState = JSON.parse(JSON.stringify(state));
                            newState.customer_addresses[0].state =
                              e.target.value;
                            return newState;
                          });
                        }}
                      >
                        {address.map((val, index) => (
                          <option key={`${index}-state`} value={val.value}>
                            {val.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block accDetail-form-label"
                    >
                      {translate("country")}
                    </label>
                    <div className="mt-2">
                      <select
                        name="country"
                        id="country"
                        className="block w-full rounded-md border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      >
                        <option value="">Myanmar</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="city"
                      className="block accDetail-form-label"
                    >
                      {translate("city")}
                    </label>
                    <div className="mt-2">
                      <select
                        defaultValue={
                          customer.customer_addresses.length > 0
                            ? customer.customer_addresses[0].city
                            : ""
                        }
                        name="city"
                        id="city"
                        onChange={(e) => {
                          setCustomer((state) => {
                            let newState = JSON.parse(JSON.stringify(state));
                            newState.customer_addresses[0].city =
                              e.target.value;
                            return newState;
                          });
                        }}
                        className="block w-full rounded-md border-0 py-2.5 px-4 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      >
                        {customer.customer_addresses.length > 0
                          ? address
                              .filter(
                                (state) =>
                                  state.value ===
                                  customer.customer_addresses[0].state
                              )[0]
                              ?.children.map((val, index) => (
                                <option
                                  key={`${val.value}-city`}
                                  value={val.value}
                                >
                                  {val.label}
                                </option>
                              ))
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3 mt-9">
                  <label
                    htmlFor="address"
                    className="block accDetail-form-label"
                  >
                    {translate("detailAddress")}
                  </label>
                  <div className="mt-2">
                    <textarea
                      value={
                        customer.customer_addresses.length > 0
                          ? customer.customer_addresses[0].address
                          : ""
                      }
                      onChange={(e) => {
                        setCustomer((state) => {
                          let newState = JSON.parse(JSON.stringify(state));
                          newState.customer_addresses[0].address =
                            e.target.value;
                          return newState;
                        });
                      }}
                      name="address"
                      id="address"
                      cols="20"
                      rows="5"
                      placeholder="No.9,Yakin Street"
                      required
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center">
                <p
                  onClick={() => {
                    UpdateCustomer();
                  }}
                  className="cursor-pointer my-4 py-4 px-12 font-Cushy_W01 text-white bg-[var(--primary-color)] rounded-xl"
                >
                  {translate("saveChange")}
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountDetails;
