import React from "react";
import useLocales from "../../../hook/uselocales";

function Hero() {
  const { translate } = useLocales();

  return (
    <section className="w-full flex justify-center items-center product-hero">
      <h1 className="font-Annifont text-white heading-text">
        {translate("product")}
      </h1>
    </section>
  );
}

export default Hero;
